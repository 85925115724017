import React from "react";


export const getPipe = () => {
	{/* @ts-ignore */}
return <svg version="1.1" id="Capa_1"  x="0px" y="0px" viewBox="0 0 58 58" style={{ transform: "rotate(45deg)"}}>
<rect x="18" y="3" style={{fill: "#D2FF4D"}} width="22" height="6"/>
<polyline style={{fill:"#96BE2F"}} points="37,49 37,9 21,9 21,49 "/>
<rect x="21" style={{fill:"#96BE2F"}} width="16" height="3"/>
<rect x="18" y="49" style={{fill: "#D2FF4D"}} width="22" height="6"/>
<rect x="21" y="55" style={{fill:"#96BE2F"}} width="16" height="3"/>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
}
